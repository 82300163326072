import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./App.scss";
import MapImage from "./images/map.jpg";

function App() {
  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  const account = String(document.location.host).includes('hm') || String(document.location.host).includes('yy');

  return (
    <main className="wedding-0625">
      <article className="intro">
        {/*<h1>결혼합니다</h1>*/}
        <h2>박영용♥김현민</h2>
        <h3>2022.06.25 토요일 낮 12시30분</h3>
        <h4>더리버사이드호텔 노벨라홀</h4>
        {/*<h5>노벨라홀</h5>*/}
      </article>
      <article className="poem">
        <p>
          <i className="flower" />
          꽃 같은 그대<br />
          나무 같은 나를 믿고 길을 나서자<br />
          그대는 꽃이라서<br />
          10년이면 10번 변하겠지만<br />
          나는 나무 같아서 그 10년,<br />
          내 속에 둥근 나이테로만 남기고 말겠다.<br />
          타는 가슴이야 내가 알아서 할 테니<br />
          길가는 동안 내가 지치지 않게<br />
          그대의 꽃향기 잃지 않으면 고맙겠다.<br />
          <br />
          &lt;동행 - 이수동&gt;
        </p>
      </article>
      <article className="hello">
        
      </article>
      <article className="invite">
        <em>◇</em>
        <h1>2022.06.25</h1>
        <h2>초대합니다</h2>
        <em>◇</em>
        <p className="message">
          저희 두 사람,<br />
          한길을 같이 걷고자 합니다.<br />
          첫걸음을 내딛는 귀한 날<br />
          찾아와주셔서 축복과 격려로<br />
          함께해주시면 감사하겠습니다.
        </p>
        <hr />
        <p className="family">
          박상집&middot;구원희<span>의 아들</span> <strong>영용</strong><br />
          김기중&middot;한명숙<span>의 딸</span> <strong>현민</strong>
        </p>
        <hr />
      </article>
      <article className="gallery">
        <h1>Gallery</h1>
        {/*<h2>사진을 클릭하시면 크게 보실 수 있습니다.</h2>*/}
        {/*<div className="gallery">
          <ul>
            <li><p className="x2 img-01"></p></li>
            <li><p className="x2 img-05"></p></li>
            <li><p className="x2 img-03"></p></li>
            <li><p className="x2 img-06"></p></li>
            <li><p className="x2 img-12"></p></li>
          </ul>
          <ul>
            <li><p className="x1 img-04"></p></li>
            <li><p className="x2 img-10"></p></li>
            <li><p className="x2 img-11"></p></li>
            <li><p className="x2 img-15"></p></li>
            <li><p className="x2 img-14"></p></li>
          </ul>
        </div>*/}
        <Carousel showArrows={true} showThumbs={false}>
          <div><p className="img-06"></p></div>
          <div><p className="img-01"></p></div>
          <div><p className="img-03"></p></div>
          <div><p className="img-04"></p></div>
          <div><p className="img-05"></p></div>
          <div><p className="img-10"></p></div>
          <div><p className="img-11"></p></div>
          <div><p className="img-15"></p></div>
          <div><p className="img-12"></p></div>
          <div><p className="img-14"></p></div>
        </Carousel>
      </article>
      <article className="calendar">
        <h1>유월의<br />스물다섯번째날.</h1>
        <hr />
        <ul>
          <li>S</li>
          <li>M</li>
          <li>T</li>
          <li>W</li>
          <li>T</li>
          <li>F</li>
          <li>S</li>
          <li></li>
          <li></li>
          <li></li>
          {'*'.repeat(30).split('').map((_, i) => <li key={i} className={i + 1 === 25 ? "dday" : undefined}>{i + 1}</li>)}
        </ul>
        <hr />
      </article>
      <article className="map">
        <hr />
        <h1>오시는 길</h1>
        <hr />
        <h2>서울 서초구 강남대로107길 6</h2>
        <h3>더리버사이드호텔 노벨라홀</h3>
        {/*<div className="map" style={{ height: '400px', width: '100%' }}>
          {isLoaded ? <GoogleMap
            mapContainerStyle={{height: '400px', width: '100%'}}
            center={{lat: 37.5184736, lng: 127.0181633}}
            zoom={17}
          >
            <></>
          </GoogleMap> : <></>}
        </div>*/}
        <div className="map-image">
          <img src={MapImage} alt="MAP" />
          <p>
            <p>
              <strong>지하철</strong><br />
              3호선 신사역 하차 후, 5번 출구에서 도보 3분 거리<br />
            </p>
            <p>
              <strong>버스</strong><br />
              간선(파랑): 142, 140, 144, 145, 241, 400, 402, 420, 421, 440, 441, 470, 471, 542, N13, N37<br />
              지선(초록): 서초03, 4412<br />
              광역(빨강): 직행 1005-1, 1100, 3030, 3100, 9600, 9700, G7426, 급행 8001 / 광역 9404, 9711A, M7412<br />
            </p>
            <p>
              <strong>자가용</strong><br />
              <strong>강변북로 방면</strong><br />
              1. 구리 → 일산방향: 한남오거리 유턴 → 한남대교(남단)건너 → 강남대로(신사역)방향(150M) → 우회전시 호텔 진입<br />
              2. 일산 → 구리방향: 한남대교방향(남단)건너 → 강남대로(신사역)방향(150M) → 우회전시 호텔 진입<br />
              <strong>올림픽대로 방면</strong><br />
              1. 잠실 → 김포공항방향: 강남대로 방향 → P턴 → 신사역방향(150M) → 우회전시 호텔 진입<br />
              2. 김포공항 → 잠실방향: 한남대교(남단)방향 → P턴 → 신사역방향 직진(150M) → 우회전시 호텔진입<br />
              <strong>경부고속도로</strong>: 잠원IC에서 압구정로 방면 → P턴 → 잠원로 방면 → 신사역방향 좌회전(200M) → 우회전시 호텔 진입
            </p>
          </p>
        </div>
      </article>
      {account && <article className="account">
        <em>♥</em>
        <h1>신랑 &middot; 신부에게 마음 전하기</h1>
        <h2>신랑측</h2>
        <h3>대구은행 164-08-009799 (예금주: 박상집)</h3>
        <h3>대구은행 164-08-009808 (예금주: 구원희)</h3>
        <hr />
        <h2>신부측</h2>
        <h3>농협 352-1979-8408-13 (예금주: 김기중)</h3>
        <h3>농협 130028-51-059801 (예금주: 한명숙)</h3>
      </article>}
      <article className="footer">
        <strong>영용♥현민</strong>
        <span>06.25 12:30PM</span>
      </article>
    </main>
  );
}

export default App;
